import React, { memo, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import type { DLClickEventHandlerProps } from 'tsconfig/types';
import { RenderDynamicComponent } from './RenderDynamicComponent/RenderDynamicComponent';

export type CmsContentSectionProps = {
  contentJsonList: any;
  dlClickEventHandler?: DLClickEventHandlerProps;
};

type RenderDynamicComponentsProps = {
  componentDataList: any[];
  dlClickEventHandler?: DLClickEventHandlerProps;
};

const RenderDynamicComponents = (props: RenderDynamicComponentsProps) => {
  const { componentDataList, dlClickEventHandler } = props;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <React.Fragment>
      {componentDataList?.map(item => (
        <RenderDynamicComponent
          contentItem={item}
          dlClickEventHandler={dlClickEventHandler}
          key={`component-${item?.sys?.id}`}
        />
      ))}
    </React.Fragment>
  );
};

export const MemoizedRenderDynamicComponents = memo<RenderDynamicComponentsProps>(RenderDynamicComponents);

export const CmsContentSection = memo((props: CmsContentSectionProps) => {
  const { contentJsonList, dlClickEventHandler } = props;
  const theme = useTheme();
  const widgetTheme = theme.widgets.CmsContentSection || {};

  const Wrapper = useMemo(
    () => styled.section`
      ${widgetTheme.wrapper}
    `,
    [],
  );

  if (!contentJsonList) {
    return null;
  }

  return (
    <Wrapper data-test="dynamic-components" className="dynamicComponents">
      <MemoizedRenderDynamicComponents componentDataList={contentJsonList} dlClickEventHandler={dlClickEventHandler} />
    </Wrapper>
  );
});
