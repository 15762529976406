import React, { useEffect, useState, useMemo } from 'react';
import { css, useTheme } from '@emotion/react';
import Grid from '../../grid/Grid';
import type { BannerProps } from 'tsconfig/types';
import styled from '@emotion/styled';
import { CmsImage } from '../CmsImage/CmsImage';
import { CmsLink } from '../CmsLink/CmsLink';
import { Button, inspectorMode, useScreenLayout } from 'common-ui';
import { fontCorrectionInRichText } from '../../../utils/fontCorrectionInRichText';
import BannerWithContentAndCTA from '../../BannerComponents/BannerComponentWithContentAndCta';
import { BannerWithButton } from '../../BannerComponents/BannerComponentWithButton';
import {
  getClassNameBasedOnVariant,
  getContentWidth,
  getDataTestId,
  getTintedBackground,
  hasTintedBackground,
  getGradient,
} from '../../BannerComponents/BannerUtility';
import getPixalImageDataUrl from '../../../utils/getPixalImageDataUrl';
import SkeletonBanner from '../../Skeleton/Banner/Banner';
import { navigateToBannerLink, stopBannerContentEventPropagation } from './CmsBannerUtility';

type CmsBannerType = React.MemoExoticComponent<({ contentItem }: BannerProps) => JSX.Element> & {
  BannerWithContentAndCTA: typeof BannerWithContentAndCTA;
};

export const CmsBanner: CmsBannerType = Object.assign(
  React.memo(({ contentItem }: BannerProps) => {
    const {
      variant = '',
      CTALink = [],
      contentBackgroundColour = '',
      bannerContent = '',
      alignment = '',
      image,
      backgroundImage,
      contentWidth = '',
      minimumBannerHeight,
      bannerLink,
      addGradient = true,
      hideImageOnMobile = false,
    } = contentItem.fields || {};

    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();
    const mediaSm = theme.breakpoints.sm ? theme.breakpoints.xs.replace('@media', '') : '';

    const { screen, isNotDesktop } = useScreenLayout();
    const isMobileViewPort = typeof window !== 'undefined' && isNotDesktop;
    const screenDetected = typeof window !== 'undefined' ? screen : null;

    // Optimize image URL determination
    const backgroundImageUrl = useMemo(() => {
      if (!screenDetected) {
        return '';
      }
      const smallDeviceUrl = backgroundImage?.fields?.smallDeviceImage?.fields?.file?.url;
      const largeDeviceUrl = backgroundImage?.fields?.largeDeviceImage?.fields?.file?.url;
      return isMobileViewPort ? smallDeviceUrl || largeDeviceUrl : largeDeviceUrl;
    }, [screenDetected, isMobileViewPort, backgroundImage]);

    const isCenterTintedBackground = variant === 'Full Background - Tinted Centre';
    const bannerContentWidth = getContentWidth(contentWidth);
    const tintedBackground = hasTintedBackground(variant);
    const hideImageMobile =
      hideImageOnMobile &&
      (variant === 'Content Left / Image Right' || variant === 'Content Right / Image Left') &&
      isMobileViewPort;

    useEffect(() => {
      if (bannerContent) {
        setIsLoading(false);
      }
    }, [bannerContent]);

    // Use effect to preload images
    useEffect(() => {
      const preloadImage = (url: string | undefined) => {
        if (!url) {
          return;
        }
        const link = document.createElement('link');
        link.rel = 'preload';
        link.href = url;
        link.as = 'image';
        document.head.appendChild(link);
      };

      const smallImage = image?.fields?.smallDeviceImage?.fields?.file?.url;
      const largeImage = image?.fields?.largeDeviceImage?.fields?.file?.url;

      preloadImage(isMobileViewPort ? smallImage : largeImage);
      preloadImage(backgroundImageUrl);
    }, [image, backgroundImageUrl, isMobileViewPort]);

    const isLargeVideo = backgroundImage?.fields?.largeDeviceImage?.fields?.file?.contentType?.includes('video');
    const isSmallVideo = backgroundImage?.fields?.smallDeviceImage?.fields?.file?.contentType?.includes('video');

    const wrapper = css`
      ${theme.widgets.CmsBanner?.default};
      margin-bottom: 10px;
      container: banner / inline-size;
      ${hasTintedBackground(variant) && `position: relative;`}
      .banner {
        background-color: ${contentBackgroundColour};
        padding: 0;
        ${bannerLink && `cursor: pointer`};
        ${getTintedBackground(variant, backgroundImageUrl, theme.breakpoints.sm, addGradient)}
        ${theme.breakpoints.md} {
          ${minimumBannerHeight && `min-height: ${minimumBannerHeight}px`};
        }
        .banner-image-wrapper {
          ${tintedBackground
            ? `@media (max-width: 768px) {  
                padding: 20px 0px 20px 0px;
                display: flex;
                justify-content: center;
                max-height: 400px;
              }`
            : image
              ? `background: url(${backgroundImageUrl});
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;`
              : ''}
          ${minimumBannerHeight ? `min-height: ${minimumBannerHeight}px` : `min-height: 100%`};
          ${theme.breakpoints.md} {
            min-height: auto;
          }
          min-width: 100%;
          position: relative;
          ${theme.breakpoints.xs} {
            min-width: auto;
          }
          .banner-imgcomp {
            position: relative;
            display: flex;
            justify-content: center;
            z-index: 1;
          }
          .banner-bg-img {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .cms-image-container {
              flex: 1;
            }
          }
        }
        .banner-content-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
          ${tintedBackground
            ? 'z-index: 1;'
            : `background: ${
                contentBackgroundColour
                  ? (theme.colors.bgColors as unknown as Record<string, string>)[contentBackgroundColour]
                  : 'initial'
              };
        `}
          text-align: ${alignment.toLowerCase()};
          .banner-info {
            .banner-button {
              justify-content: ${alignment.toLowerCase()};
            }
          }
        }
      }

      @container banner (width < ${parseInt(theme.breakpoints.md.split(' ')[2]) / 2.13}px) {
        .banner {
          ${tintedBackground &&
          `background:
            ${addGradient ? `${getGradient(variant.replace('Full Background - Tinted ', ''), true)}, ` : ''}
            url(${backgroundImageUrl}) lightgray 50% / cover no-repeat;`}
          .banner-content-wrapper,
          .banner-image-wrapper {
            width: 100%;
          }
          .banner-info {
            padding: 16px;
          }
          .banner-content {
            margin-bottom: 12px;
            border-radius: 8px;
            ${theme.breakpoints.sm} {
              padding: 24px 16px;
              margin-bottom: 24px;
            }
            h2 {
              font-size: 30px;
              text-wrap: pretty;
            }
          }
        }
      }
    `;

    const Section = styled.section`
      ${theme.widgets.CmsBanner?.wrapper}
    `;

    {
      isLoading && <SkeletonBanner variant={variant} />;
    }

    if (variant === 'Full width background banner with Calls to Action') {
      return <BannerWithButton contentItem={contentItem} />;
    }

    const getVideoContent = () => {
      const device = isMobileViewPort ? 'smallDeviceImage' : 'largeDeviceImage';
      return (
        <video
          autoPlay
          muted
          loop
          className="banner-bg-img desktop-video"
          style={{ width: '100%', height: '100%', position: 'absolute', top: '0', left: '0', objectFit: 'cover' }}
          src={backgroundImage?.fields[device]?.fields?.file?.url}
        >
          <source
            src={backgroundImage?.fields[device]?.fields?.file?.url}
            type={backgroundImage?.fields[device]?.fields?.file?.contentType}
          />
          Your browser does not support the video tag.
        </video>
      );
    };

    const cmsImage = (
      <CmsImage
        contentItem={backgroundImage}
        sizes={`${mediaSm}: ${((12 - bannerContentWidth) / 12) * 100}vw, 100vh`}
        fill={true}
        isNextImg={false}
        blurDataURL={getPixalImageDataUrl(contentBackgroundColour)}
        placeholder="blur"
      />
    );

    const contentWidthBackground = () => {
      if (isLargeVideo && isSmallVideo) {
        return getVideoContent();
      } else if (isLargeVideo) {
        return !isMobileViewPort ? getVideoContent() : !image && <div className="banner-bg-img">{cmsImage}</div>;
      } else if (isSmallVideo) {
        return isMobileViewPort ? getVideoContent() : !image && <div className="banner-bg-img">{cmsImage}</div>;
      }
      return !image && <div className="banner-bg-img">{cmsImage}</div>;
    };

    const getButtonClass = (classVariant: string) => {
      switch (classVariant) {
        case 'Secondary button':
          return 'darkColor';
        case 'Secondary-inverted button':
          return 'lightColor';
        default:
          return '';
      }
    };

    return (
      <div
        css={wrapper}
        data-test="banner"
        data-testid={`banner-${getDataTestId(variant)}`}
        onClick={() => navigateToBannerLink(bannerLink)}
        onKeyDown={e => e.key === 'Enter' && navigateToBannerLink(bannerLink)}
        role="button"
        tabIndex={0}
        className="banner-wrapper"
      >
        <Grid justify="start" className="banner">
          {!isCenterTintedBackground && (
            <Grid.Col
              md={contentWidth ? 12 - bannerContentWidth : 6}
              xs={12}
              className={`banner-image-wrapper ${getClassNameBasedOnVariant(variant, false)}`}
            >
              {screenDetected && image && !hideImageMobile && (
                <div className="banner-imgcomp">
                  <CmsImage isNextImg={true} contentItem={image} fill={true} />
                </div>
              )}
              {!hasTintedBackground(variant) && backgroundImage && contentWidthBackground()}
            </Grid.Col>
          )}

          <Grid.Col
            md={bannerContentWidth}
            xs={12}
            className={`banner-content-wrapper ${getClassNameBasedOnVariant(variant, true)}`}
          >
            <div data-testid={`banner-info-${alignment.toLowerCase()}`} className="banner-info">
              <Section
                data-test="contentful-textbox"
                data-testid={`banner-content-${bannerContentWidth}`}
                className="banner-content"
              >
                {bannerContent && /<[a-z][\s\S]*>/i.test(bannerContent) && (
                  <div
                    onClick={stopBannerContentEventPropagation}
                    onKeyDown={stopBannerContentEventPropagation}
                    dangerouslySetInnerHTML={{ __html: fontCorrectionInRichText(bannerContent, theme.fonts) }}
                    {...inspectorMode(contentItem?.sys?.id, 'bannerContent')}
                    role="textbox"
                    tabIndex={0}
                  />
                )}
              </Section>
              {CTALink.length > 0 && (
                <div className="banner-button">
                  {CTALink.map((value: any) =>
                    value?.fields?.minifiedButtonStyle ? (
                      <a href={value?.fields?.url} key={value?.fields?.title}>
                        <Button
                          className={getButtonClass(value?.fields?.variant)}
                          kind="tertiary"
                          {...inspectorMode(value?.sys?.id, 'title')}
                        >
                          {value?.fields?.title}
                        </Button>
                      </a>
                    ) : (
                      <CmsLink key={`banner-cta-${value?.fields?.title}`} contentItem={value} />
                    ),
                  )}
                </div>
              )}
            </div>
          </Grid.Col>
        </Grid>
        {hasTintedBackground(variant) && backgroundImage && contentWidthBackground()}
      </div>
    );
  }),
  { BannerWithContentAndCTA },
);

CmsBanner.BannerWithContentAndCTA = BannerWithContentAndCTA;
