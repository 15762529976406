import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { cartAtom } from '../atoms/cartAtom';
import { logger } from 'common-ui';
import { clearCartError } from '../helpers/clearCartError';

const useWaitForCartToLoad = (config?: { maxAttempts?: number }) => {
  const cartData = useRecoilValue(cartAtom);
  const cartDataRef = useRef(cartData);

  useEffect(() => {
    cartDataRef.current = cartData;
  }, [cartData]);

  return async (): Promise<void> => {
    if (cartData.cartError.hasCartError) {
      return Promise.reject(new Error(cartData?.cartError?.cartErrorMessage));
    }
    if (cartData.cartIsLoaded) {
      return Promise.resolve();
    }

    // TODO: Consider moving this to environment variable
    const maxAttempts = config?.maxAttempts || 20;
    let attempts = 0;

    return new Promise<void>((resolve, reject) => {
      const intervalId = setInterval(() => {
        const currentCartData = cartDataRef.current;

        if (currentCartData?.cartError?.hasCartError) {
          clearInterval(intervalId);
          reject(new Error(currentCartData?.cartError?.cartErrorMessage));
        }

        if (currentCartData.cartIsLoaded) {
          clearInterval(intervalId);
          resolve();
        } else if (attempts >= maxAttempts) {
          clearInterval(intervalId);
          logger.error('Max attempts reached: cart could not be loaded');
          reject(new Error('Something went wrong. Please try again.'));
        }
        attempts++;
      }, 1000);

      // Cleanup interval on component unmount
      return () => {
        clearInterval(intervalId);
        clearCartError(5000);
      };
    });
  };
};

export default useWaitForCartToLoad;
