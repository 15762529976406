import type { ReactNode } from 'react';
import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { Drawer } from '@mantine/core';
import type { CallbackFunction, LinkEntryType } from 'tsconfig/types';
import { CmsLink, HeaderLogo, Icon } from 'common-ui';
import { useDisclosure } from '@mantine/hooks';

export type DrawerMenuProps = {
  target: ReactNode;
  children: ReactNode;
  position?: 'left' | 'right';
  onClose?: CallbackFunction;
  className?: string;
  isAccessibleFor?: string;
  stickyContent?: LinkEntryType[];
};

export const DrawerMenu = memo((props: DrawerMenuProps) => {
  const { target, children, className, position = 'right', onClose, isAccessibleFor, stickyContent } = props;
  const theme = useTheme();
  const [opened, { close, toggle }] = useDisclosure(false);

  const mobileDrawerContainerStyles = css`
    .mantine-Drawer-overlay,
      .mantine-Drawer-inner {
        ${theme.breakpoints.lg} {
          display: none;
        }
      } 
    }
  `;

  function doClose() {
    close();
    if (onClose) {
      onClose();
    }
  }

  return (
    <div>
      <div onClick={toggle} onKeyDown={toggle} role="button" tabIndex={0}>
        {target}
      </div>
      <Drawer
        opened={opened}
        onClose={doClose}
        withCloseButton={false}
        css={[
          theme.widgets.DrawerMenu?.drawerContainer,
          isAccessibleFor === 'notDesktop' && mobileDrawerContainerStyles,
        ]}
        position={position}
        className={className}
        keepMounted
      >
        <div className="drawer-header-logo-section">
          <HeaderLogo />
          <div onClick={doClose} onKeyDown={doClose} role="button" tabIndex={0}>
            <Icon kind="close" size="xs" />
          </div>
        </div>
        <div data-testid="drawer-main" className="drawer-main">
          {children}
        </div>
        {stickyContent && (
          <div className="drawer-footer-sticky-button-section">
            {(stickyContent as LinkEntryType[]).map(link => (
              <div key={`sticky-button-${link.fields?.title}`}>
                <CmsLink contentItem={link} />
              </div>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
});
