import React, { memo } from 'react';
import type { ArticleEntryType } from 'tsconfig/types';
import { Article } from '../../Article/Article';

export type CmsArticleProps = {
  contentItem: ArticleEntryType;
};

export const CmsArticle: React.FC<CmsArticleProps> = memo(({ contentItem: { fields, sys } }) => {
  const { entryTitle, link, media, primaryHeader, secondaryHeader, content, publishedDate } = fields || {};

  return media && content ? (
    <Article
      entryTitle={entryTitle}
      link={link}
      media={media}
      content={content}
      primaryHeader={primaryHeader}
      secondaryHeader={secondaryHeader}
      publishedDate={publishedDate}
      id={sys?.id}
    />
  ) : null;
});
