import React, { memo, useState } from 'react';
import type { AccordionProps } from 'tsconfig/types';
import Accordion from '../../Accordion/Accordion';

export const CmsAccordion = memo(({ contentItem }: AccordionProps) => {
  const { reference } = contentItem.fields || {};
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const schema = (data: any) => {
    const schemaListItems = (scheduleItems: any) => {
      const getAnswers = (answer: any) => answer.content;

      return scheduleItems?.map((hit: any) => ({
        '@type': 'Question',
        name: hit.fields.entryTitle,
        acceptedAnswer: {
          '@type': 'Answer',
          text: getAnswers(hit?.fields),
        },
      }));
    };

    return {
      '@context': 'http://schema.org',
      '@type': 'FAQPage',
      mainEntity: schemaListItems(data),
    };
  };

  return (
    <>
      {reference &&
        reference?.map((item: any, index: number) => (
          <Accordion
            key={`item-${item.sys?.id}`}
            id={`item-${item.sys?.id}`}
            entryTitle={item.fields && item.fields.entryTitle}
            content={item?.fields?.content}
            index={index}
            activeIndex={activeIndex}
            handleClick={(indexValue: number) => handleClick(indexValue)}
          />
        ))}

      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema(reference)) }} />
    </>
  );
});
