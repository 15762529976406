import { Skeleton } from '../Skeleton';
import { css, useTheme } from '@emotion/react';

const BannerSkeleton = ({ variant }: { variant?: string }) => {
  const theme = useTheme();
  const bannerSkeleton = css`
    ${theme.widgets.CmsBanner?.bannerSkeleton}
  `;

  switch (variant as string) {
    case 'Content Left / Image Right':
      return (
        <div css={bannerSkeleton} data-test="BannerSkeleton-ContentLeftImageRight">
          <div className="text-section">
            <Skeleton height={70} width="90%" radius="sm" />
            <Skeleton height={20} width="80%" radius="sm" mt={16} />
            <Skeleton height={20} width="50%" radius="sm" mt={16} />
            <Skeleton height={20} width="40%" radius="sm" mt={24} />
            <div className="cta-section">
              <Skeleton height={56} width="120px" radius="md" mt={32} />
              <Skeleton height={56} width="120px" radius="md" mt={32} />
            </div>
          </div>
          <div className="image-section">
            <Skeleton height={500} width={1000} radius="sm" />
          </div>
        </div>
      );

    case 'Content Right / Image Left':
      return (
        <div css={bannerSkeleton} data-test="BannerSkeleton-ContentRightImageLeft">
          <div className="image-section">
            <Skeleton height={500} width={800} radius="sm" />
          </div>
          <div className="text-section">
            <Skeleton height={70} width="90%" radius="sm" />
            <Skeleton height={20} width="80%" radius="sm" mt={16} />
            <Skeleton height={20} width="50%" radius="sm" mt={16} />
            <Skeleton height={20} width="40%" radius="sm" mt={24} />
            <div className="cta-section">
              <Skeleton height={56} width="120px" radius="md" mt={32} />
              <Skeleton height={56} width="120px" radius="md" mt={32} />
            </div>
          </div>
        </div>
      );

    case 'Full Background - Tinted Left':
      return (
        <div
          css={bannerSkeleton}
          data-test="BannerSkeleton-FullBackgroundTintedLeft"
          style={{
            paddingLeft: '15vw',
            textAlign: 'center',
            height: '100%',
          }}
        >
          <div className="tinted-section" style={{ textAlign: 'left', height: '100%' }}>
            <Skeleton height={70} width="280%" radius="sm" />
            <Skeleton height={20} width="260%" radius="sm" mt={16} />
            <Skeleton height={20} width="240%" radius="sm" mt={16} />
            <div className="cta-section">
              <Skeleton height={56} width="120px" radius="md" mt={32} />
            </div>
          </div>
        </div>
      );

    case 'Full Background - Tinted Centre':
      return (
        <div
          css={bannerSkeleton}
          data-test="BannerSkeleton-FullBackgroundTintedCenter"
          style={{
            paddingLeft: '45vw',
            textAlign: 'center',
            height: '100%',
          }}
        >
          <div
            className="tinted-section"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <div className="cta-section">
              <Skeleton height={56} width="120px" radius="md" mt={32} />
            </div>
            <Skeleton height={50} width="270%" radius="sm" mt={16} />
            <Skeleton height={20} width="250%" radius="sm" mt={16} />
            <Skeleton height={20} width="230%" radius="sm" mt={16} />
          </div>
        </div>
      );
    case 'Full Background - Tinted Right':
      return (
        <div
          css={bannerSkeleton}
          data-test="BannerSkeleton-FullBackgroundTintedRight"
          style={{
            paddingLeft: '85vw',
            textAlign: 'center',
            height: '100%',
          }}
        >
          <div
            className="tinted-section"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              justifyContent: 'center',
              textAlign: 'right',
              height: '100%',
            }}
          >
            <div className="cta-section">
              <Skeleton height={56} width="120px" radius="md" mt={32} />
            </div>
            <Skeleton height={70} width="260%" radius="sm" mt={16} />
            <Skeleton height={20} width="220%" radius="sm" mt={16} />
            <Skeleton height={20} width="230%" radius="sm" mt={16} />
          </div>
        </div>
      );

    case 'Full width background banner with Calls to Action':
      return (
        <div
          css={bannerSkeleton}
          data-test="BannerSkeleton-FullBackgroundCallToAction"
          style={{
            paddingLeft: '45vw',
            textAlign: 'center',
            height: '100%',
          }}
        >
          <div
            className="tinted-section"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <div className="cta-section">
              <Skeleton height={56} width="120px" radius="md" mt={82} />
            </div>
            <Skeleton height={60} width="280%" radius="sm" mt={24} />
            <Skeleton height={80} width="220%" radius="sm" mt={18} />
            <Skeleton height={40} width="260%" radius="sm" mt={20} />
            <Skeleton height={40} width="240%" radius="sm" mt={18} mb={48} />
          </div>
        </div>
      );

    default:
      return (
        <div
          css={bannerSkeleton}
          style={{
            paddingLeft: '45vw',
            textAlign: 'center',
            height: '100%',
          }}
          data-test="BannerSkeleton-Default"
        >
          <div
            className="tinted-section"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              height: '100%',
            }}
          >
            <div className="cta-section">
              <Skeleton height={56} width="120px" radius="md" mt={32} />
            </div>
            <Skeleton height={50} width="270%" radius="sm" mt={16} />
            <Skeleton height={20} width="250%" radius="sm" mt={16} />
            <Skeleton height={20} width="230%" radius="sm" mt={16} />
          </div>
        </div>
      );
  }
};

export default BannerSkeleton;
