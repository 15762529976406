import type { GlobalSettings, ContentfulApiInitConfig } from 'tsconfig/types.d';
import { ContentfulClient } from './contentful/contentfulClient';
import { compressContentfulJson, logger } from 'common-ui';

// Define `initContentfulAPI` before `initContentfulAPIWithGlobalSettings`
export const initContentfulAPI = (config: ContentfulApiInitConfig) => {
  const contentfulClient = new ContentfulClient(config);
  const client = contentfulClient.primaryClient;

  const processResponse = (response: any) => {
    if (config.isPreview) {
      return response;
    }
    return compressContentfulJson(response);
  };

  return {
    // Fetches a list of all Content Pages
    fetchListOfAllContentPages: async () => {
      try {
        const rawPagesData = await client.withoutUnresolvableLinks.getEntries({
          content_type: 'contentPage',
          include: 0, // No child items - we only want the list of pages
        });
        return processResponse(rawPagesData.items);
      } catch (e) {
        logger.error('Error', e);
      }
    },

    fetchSiteConfig: async () => {
      const siteConfig = await contentfulClient.fetchSiteConfig();
      return processResponse(siteConfig);
    },

    fetchContentPageForSlug: async (slug: string) => {
      const page = await contentfulClient.fetchContentPage({ 'fields.slug': slug });
      if (page && page?.length > 1) {
        logger.error(
          `More than 1 ContentPage item found for slug '${slug}'. Using first`,
          JSON.stringify(page, null, 2),
        );
      }
      return processResponse(page?.[0]);
    },
  };
};

// Use `initContentfulAPI` after its definition
export const initContentfulAPIWithGlobalSettings = (globalSettings: GlobalSettings) => {
  return initContentfulAPI({
    space: globalSettings.contentfulSpace as string,
    environment: globalSettings.contentfulEnvironment as string,
    accessToken: (globalSettings.contentfulPreviewMode
      ? globalSettings.contentfulPreviewToken
      : globalSettings.contentfulToken) as string,
    host: globalSettings.contentfulPreviewMode ? 'preview.contentful.com' : 'cdn.contentful.com',
    contentfulOrchestration: globalSettings.contentfulOrchestration as string,
    isPreview: globalSettings.contentfulPreviewMode as boolean,
  });
};
