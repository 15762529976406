import { logger } from 'common-ui';
import { createCustomApiError } from './createCustomApiError';

export function handlePromoCodeValidation(newCartData?: { responseCode?: string }): {
  promoCode?: string;
  cid?: string;
} {
  const urlParams = new URLSearchParams(window.location.search);

  // Check for promoCode in a case-insensitive manner
  const promoCodeKey = Array.from(urlParams.keys()).find(key => key.toLowerCase() === 'promocode');
  const cidKey = Array.from(urlParams.keys()).find(key => key.toLowerCase() === 'cid');
  const cid = cidKey ? urlParams.get(cidKey) ?? '' : '';

  if (promoCodeKey) {
    const promoCode = urlParams.get(promoCodeKey) || '';

    if (newCartData && newCartData.responseCode?.toLowerCase() !== promoCode.toLowerCase()) {
      logger.log('promoCode found');
      throw createCustomApiError('New response code found!', 404);
    } else {
      logger.log('promoCode not found');
    }

    return { promoCode, ...(cid ? { cid } : {}) };
  }

  if (!promoCodeKey && cidKey) {
    return { cid };
  }

  return {};
}
