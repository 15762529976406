import React, { memo, useEffect, useState } from 'react';
import { CmsExpander } from '../../CmsExpander/CmsExpander';
import { CmsTabs } from '../../CmsTabs/CmsTabs';
import type { DLClickEventHandlerProps, CarouselEntryType, ColumnEntryType } from 'tsconfig/types';
import {
  CmsAccordion,
  CmsBanner,
  CmsContainer,
  CmsImage,
  CmsLink,
  CmsMenuBanner,
  CmsRichText,
  CmsTimer,
  CmsVideoComponent,
  CmsArticle,
  CmsCarousel,
  CmsConditionalContainer,
  CmsNavigation,
  CmsProductCard,
  CmsText,
  CmsWidget,
  logger,
  CmsMultiCaseSelector,
  CmsHtmlCode,
} from 'common-ui';
import CmsColumns from '../../CmsColumns/CmsColumns';
import get from 'lodash/get';
import { MemoizedRenderDynamicComponents } from '../CmsContentSection';
import { CarouselSkeleton } from '../../../Skeleton/Carousel/Carousel';
import TimerSkeleton from '../../../Skeleton/Timer/Timer';

type RenderDynamicComponentProps = {
  contentItem: any;
  dlClickEventHandler?: DLClickEventHandlerProps;
};

export const RenderDynamicComponent = memo((props: RenderDynamicComponentProps): any => {
  const { contentItem, dlClickEventHandler } = props;
  if (!contentItem) {
    return null;
  }

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // Set to true only after component mounts on the client
    setIsClient(true);
  }, []);

  const contentTypeId = get(contentItem, 'sys.contentType.sys.id');
  const instanceId = get(contentItem, 'sys.id');

  const dlClickEventData = (data?: string) => {
    dlClickEventHandler?.(`${contentItem?.fields?.title}:${data}`);
  };

  switch (contentTypeId) {
    case 'text':
      return <CmsText contentItem={contentItem} key={instanceId} />;

    case 'productCard':
      return <CmsProductCard contentItem={contentItem} key={instanceId} />;

    case 'widget':
      return <CmsWidget contentItem={contentItem} key={instanceId} />;

    case 'link':
      return (
        <CmsLink
          contentItem={contentItem}
          key={instanceId}
          category="main navbar link"
          dlClickEventHandler={dlClickEventHandler}
        />
      );

    case 'image':
      return <CmsImage contentItem={contentItem} key={instanceId} isNextImg width={400} height={400} />;

    case 'video':
      return <CmsVideoComponent contentItem={contentItem} key={instanceId} />;

    case 'richtext':
      return <CmsRichText contentItem={contentItem} key={instanceId} />;

    case 'banner':
      const BannerComponent =
        contentItem?.fields?.variant === 'Multipurpose banner with Calls to Action'
          ? CmsBanner.BannerWithContentAndCTA
          : CmsBanner;
      return <BannerComponent contentItem={contentItem} key={instanceId} />;

    case 'menuBanner':
      return <CmsMenuBanner contentItem={contentItem} key={instanceId} dlClickEventHandler={dlClickEventHandler} />;

    case 'accordion':
      return <CmsAccordion contentItem={contentItem} key={instanceId} />;

    case 'container':
      return (
        <CmsContainer
          key={instanceId}
          backgroundColour={contentItem.fields?.backgroundColour}
          alignment={contentItem.fields?.alignment}
          pageWidthToggle={contentItem.fields?.pageWidthToggle}
        >
          <MemoizedRenderDynamicComponents componentDataList={contentItem.fields?.content} />
        </CmsContainer>
      );

    case 'navigation':
      return (
        <CmsNavigation
          contentItem={contentItem}
          key={instanceId}
          className="navigation-link"
          dlClickEventHandler={dlClickEventHandler}
        >
          <MemoizedRenderDynamicComponents
            componentDataList={contentItem.fields?.content}
            dlClickEventHandler={dlClickEventData}
          />
        </CmsNavigation>
      );
    case 'article':
      return <CmsArticle contentItem={contentItem} key={instanceId} />;
    case 'carousel':
      const slidesContent = contentItem.fields?.slides || [];
      if (!isClient) {
        return <CarouselSkeleton slidesPerBlock={contentItem.fields.slidesPerBlock} slideContent={slidesContent} />;
      }
      const slides = (slidesContent as CarouselEntryType['fields']['slides'])?.map((content, index) => (
        <CmsCarousel.CmsCarouselSlide
          // eslint-disable-next-line react/no-array-index-key
          key={`carousel-${content?.sys?.id}-${index}`}
          dataTestId={`slide-${content?.sys?.id}`}
        >
          <RenderDynamicComponent contentItem={content} dlClickEventHandler={dlClickEventHandler} />
        </CmsCarousel.CmsCarouselSlide>
      ));
      return (
        <CmsCarousel
          slidesPerBlock={contentItem.fields?.slidesPerBlock}
          slidesToScroll={contentItem.fields?.slidesToScroll}
          key={instanceId}
          dataTestId={`carousel-${contentItem.fields?.entryTitle}`}
        >
          {slides}
        </CmsCarousel>
      );
    case 'columns':
      const columnsContent = contentItem.fields?.content || [];
      const columns = columnsContent?.map((columnContent: ColumnEntryType, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <CmsColumns.CmsColumn contentItem={columnContent} key={`${columnContent?.sys?.id} column-${index}`}>
          <MemoizedRenderDynamicComponents
            componentDataList={columnContent?.fields?.content}
            dlClickEventHandler={dlClickEventHandler}
          />
        </CmsColumns.CmsColumn>
      ));
      return (
        <CmsColumns contentItem={columnsContent} key={instanceId}>
          {columns}
        </CmsColumns>
      );
    case 'conditionalContainer':
      return (
        <CmsConditionalContainer contentItem={contentItem}>
          <MemoizedRenderDynamicComponents
            componentDataList={contentItem.fields?.content}
            dlClickEventHandler={dlClickEventHandler}
          />
        </CmsConditionalContainer>
      );
    case 'expander':
      return (
        <CmsExpander
          entryTitle={contentItem?.fields?.entryTitle}
          expanderTitle={contentItem?.fields?.expanderTitle}
          closerTitle={contentItem?.fields?.closerTitle}
          backgroundColor={contentItem?.fields?.backgroundColor}
          titleColor={contentItem?.fields?.titleColor}
        >
          <RenderDynamicComponent contentItem={contentItem?.fields?.content} />
        </CmsExpander>
      );

    case 'tabs':
      const tabPanes = contentItem.fields?.tabPanes || [];
      return <CmsTabs tabPanes={tabPanes} key={instanceId} />;

    case 'timer':
      if (typeof window === 'undefined') {
        return <TimerSkeleton />;
      }
      return <CmsTimer contentItem={contentItem} />;

    case 'multiCaseSelector':
      return <CmsMultiCaseSelector contentItem={contentItem} key={instanceId} />;

    case 'htmlCode':
      return <CmsHtmlCode contentItem={contentItem} key={instanceId} />;

    default:
      logger.error('matching component not found for content item ', contentTypeId);
      return undefined;
  }
});
