import { css, useTheme } from '@emotion/react';
import type { BannerProps } from 'tsconfig/types';
import { Button, CmsImage, CmsLink, inspectorMode } from 'common-ui';
import { fontCorrectionInRichText } from '../../utils/fontCorrectionInRichText';
import { navigateToBannerLink, stopBannerContentEventPropagation } from '../contentful/CmsBanner/CmsBannerUtility';

export const BannerWithButton = ({ contentItem }: BannerProps) => {
  const { CTALink, contentBackgroundColour, bannerContent, backgroundImage, entryTitle, alignment, bannerLink } =
    contentItem.fields || {};

  const theme = useTheme();
  const wrapper = css`
    background: ${contentBackgroundColour
      ? (theme.colors.bgColors as unknown as Record<string, string>)[contentBackgroundColour]
      : 'initial'};
    background-color: ${contentBackgroundColour};
    ${bannerLink && `cursor: pointer`};
    ${theme.widgets.CmsBanner?.BannerWithButton};
    .banner-content {
      justify-content: center;
      text-align: ${alignment?.toLowerCase()};
    }
  `;

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter' || event.key === ' ') {
      navigateToBannerLink(bannerLink);
    }
  };

  const getButtonClass = (classVariant: string) => {
    switch (classVariant) {
      case 'Secondary button':
        return 'darkColor';
      case 'Secondary-inverted button':
        return 'lightColor';
      default:
        return '';
    }
  };

  return (
    <div
      css={wrapper}
      className="banner-with-button"
      data-testid="banner-full-width"
      role="button" // Adds an accessible role
      tabIndex={0} // Makes the element focusable
      onClick={() => navigateToBannerLink(bannerLink)}
      onKeyPress={handleKeyPress} // Handles keyboard interactions
    >
      <div className="banner-content">
        <div className="banner-content_container">
          <h2 {...inspectorMode(contentItem?.sys?.id, 'entryTitle')}>{entryTitle}</h2>
          <h3>
            {bannerContent && /<[a-z][\s\S]*>/i.test(bannerContent) && (
              <div
                className="description"
                role="button" // Adds an accessible role
                tabIndex={0} // Makes the element focusable
                onClick={stopBannerContentEventPropagation}
                onKeyPress={stopBannerContentEventPropagation}
                dangerouslySetInnerHTML={{ __html: fontCorrectionInRichText(bannerContent, theme.fonts) }}
                {...inspectorMode(contentItem?.sys?.id, 'bannerContent')}
              />
            )}
          </h3>
          {CTALink?.length > 0 && (
            <div className="banner-button">
              {CTALink.map((value: any) =>
                value?.fields?.minifiedButtonStyle ? (
                  <a href={value?.fields?.url} key={value?.fields?.title}>
                    <Button
                      className={getButtonClass(value?.fields?.variant)}
                      kind="tertiary"
                      {...inspectorMode(value?.sys?.id, 'title')}
                    >
                      {value?.fields?.title}
                    </Button>
                  </a>
                ) : (
                  <CmsLink key={`banner-cta-${value?.fields?.title}`} contentItem={value} />
                ),
              )}
            </div>
          )}
        </div>
      </div>
      <div className="banner-img-button">
        <CmsImage fill={true} contentItem={backgroundImage} />
      </div>
    </div>
  );
};
